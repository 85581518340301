import React, { Component } from 'react';
import { Router } from '@reach/router';
import { withTranslation } from 'react-i18next';
import axios from '../../axios/axios';
import styles from './index.module.css';
import AppContext from '../../components/Context';
const Home = React.lazy(() => import('../../pages/Home'));
const TimeSelection = React.lazy(() => import('../../pages/TimeSelection'));
const Verification = React.lazy(() => import('../../pages/Verification'));
const Confirmation = React.lazy(() => import('../../pages/Confirmation'));

class Root extends Component {
  updateRegistration = (registration) => {
    const appObj = Object.assign({}, this.state.app);
    appObj.registration = registration;
    this.setState({ app: appObj });
  };

  updateLocations = (locations) => {
    const appObj = Object.assign({}, this.state.app);
    appObj.locations = locations;
    this.setState({ app: appObj });
  };

  updateResult = (result) => {
    const appObj = Object.assign({}, this.state.app);
    appObj.result = result;
    this.setState({ app: appObj });
  };

  state = {
    config: null,
    app: {
      locations: [],
      registration: {
        firstName: null,
        lastName: null,
        email: null,
        gender: null,
        dateOfBirth: null,
        phone: null,
        locationId: null,
        timeslot: null,
        bookingDate: null
      },
      result: {
        Success: false,
        Message: null,
        Location: null,
        MemberId: null,
        Code: null
      }
    },
    updateLocations: this.updateLocations,
    updateRegistration: this.updateRegistration,
    updateResult: this.updateResult
  };
  componentDidMount() {
    axios
      .get('setup')
      .then((response) => {
        this.setState({ config: response.data, loading: false });
      })
      .catch((error) => {
        this.setState({ error: true, loading: false });
      });
  }
  render() {
    if (this.state.config) {
      return (
        <div>
          <AppContext.Provider value={this.state}>
            <div className={styles.Layout}>
              <React.Suspense fallback={<div />}>
                <Router>
                  <Home path='/' />
                  <TimeSelection path='booking' />
                  <Verification path='verification' />
                  <Confirmation path='confirmation' />
                </Router>
              </React.Suspense>
            </div>
          </AppContext.Provider>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default withTranslation()(Root);
