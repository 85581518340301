import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://api.ritualgym.com/api/v1/',
  headers: {
    Authorization: 'Token deduRcRBNuOZ2ACfxl5'
  }
});

export default instance;
